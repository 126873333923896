import Header from "../../Component/Header"
import Footer from "../../Component/Footer"
import DataContext from "../../Component/Elements/context"
import { useContext } from "react"

const GetaQuote = () => {
    const contextValues = useContext(DataContext)
    return (
        <div id="main-wrapper" class="main-wrapper">
            <Header></Header>
            <div class="edu-breadcrumb-area">
                <div class="container">
                    <div class="breadcrumb-inner">
                        <div class="page-title">
                            <h1 class="title">Get a Quote</h1>
                        </div>
                        <ul class="edu-breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Home</a></li>
                            <li class="separator"><i class="icon-angle-right"></i></li>
                            <li class="breadcrumb-item active" aria-current="page">Get a Quote</li>
                        </ul>
                    </div>
                </div>
                <ul class="shape-group">
                    <li class="shape-1">
                        <span></span>
                    </li>
                    <li class="shape-2 scene"><img data-depth="2" src="/images/about/shape-13.png" alt="shape" /></li>
                    <li class="shape-3 scene"><img data-depth="-2" src="/images/about/shape-15.png" alt="shape" /></li>
                    <li class="shape-4">
                        <span></span>
                    </li>
                    <li class="shape-5 scene"><img data-depth="2" src="/images/about/shape-07.png" alt="shape" /></li>
                </ul>
            </div>



            <section class="contact-us-area">
                <div class="container">
                    <div class="row g-5">
                        <div class="col-xl-4 col-lg-6">
                            <div class="contact-us-info">
                                <h3 class="heading-title">We're Always Eager to Hear From You!</h3>
                                <ul class="address-list">
                                    <li>
                                        <h5 class="title">Address</h5>
                                        {contextValues?.settingData?.address && (
                                            <p>{contextValues?.settingData.address}</p>

                                        )}

                                    </li>
                                    {contextValues?.settingData?.admin_support_email && <li>
                                        <h5 class="title">Email</h5>
                                        <p><a href={`mailto:${contextValues?.settingData.admin_support_email}`}> {contextValues?.settingData?.admin_support_email && (
                                            <p>{contextValues?.settingData?.admin_support_email}</p>

                                        )}</a></p>
                                    </li>}

                                    {contextValues?.settingData?.admin_support_mobile && <li>
                                        <h5 class="title">Phone</h5>
                                        <p> <a href={`tel:${contextValues?.settingData.admin_support_mobile}`}>
                                            {contextValues?.settingData.admin_support_mobile}
                                        </a></p>
                                    </li>}

                                </ul>
                                <ul class="social-share">
                                    {contextValues?.settingData?.facebook_url && <li><a href={contextValues?.settingData?.facebook_url} target='new' className="color-fb"><i className="icon-facebook"></i></a></li>}
                                    {contextValues?.settingData?.instagram_url && <li><a href={contextValues?.settingData?.instagram_url} target='new' className="color-ig"><i className="icon-instagram"></i></a></li>}
                                    {contextValues?.settingData?.twitter_url && <li><a href={contextValues?.settingData?.twitter_url} target='new' className="color-twitter"><i className="icon-twitter"></i></a></li>}
                                    {contextValues?.settingData?.linkedin_url && <li><a href={contextValues?.settingData?.linkedin_url} target='new' className="color-linkd"><i className="icon-linkedin2"></i></a></li>}
                                    {contextValues?.settingData?.youtube_url && <li><a href={contextValues?.settingData?.youtube_url} target='new' className="color-yt"> <i className="icon-youtube"></i></a></li>}

                                </ul>
                            </div>
                        </div>
                        <div class="offset-xl-2 col-lg-6">
                            <div class="contact-form form-style-2">
                                <div class="section-title">
                                    <h4 class="title">Get In Touch</h4>
                                    <p>Fill out this form for booking a consultant advising session.</p>
                                </div>
                                <form class="rnt-contact-form rwt-dynamic-form" id="contact-form" method="POST" action="mail.php">
                                    <div class="row row--10">
                                        <div class="form-group col-12">
                                            <input type="text" name="contact-name" id="contact-name" placeholder="Your name" />
                                        </div>
                                        <div class="form-group col-12">
                                            <input type="email" name="contact-email" id="contact-email" placeholder="Enter your email" />
                                        </div>
                                        <div class="form-group col-12">
                                            <input type="tel" name="contact-phone" id="contact-phone" placeholder="Phone number" />
                                        </div>
                                        <div class="form-group col-12">
                                            <textarea name="contact-message" id="contact-message" cols="30" rows="4" placeholder="Your message"></textarea>
                                        </div>
                                        <div class="form-group col-12">
                                            <button class="rn-btn edu-btn btn-medium submit-btn" name="submit" type="submit">Submit Message <i class="icon-4"></i></button>
                                        </div>
                                    </div>
                                </form>
                                <ul class="shape-group">
                                    <li class="shape-1 scene"><img data-depth="1" src="/images/about/shape-13.png" alt="Shape" /></li>
                                    <li class="shape-2 scene"><img data-depth="-1" src="/images/counterup/shape-02.png" alt="Shape" /></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div class="google-map-area">
                <div class="mapouter">
                    <div class="gmap_canvas">
                        <iframe id="gmap_canvas" src="https://maps.google.com/maps?q=melbourne,%20Australia&t=&z=15&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                    </div>
                </div>
            </div>

            <Footer></Footer>

        </div>

    )
}
export default GetaQuote