import Footer from "../../Component/Footer"
import Header from "../../Component/Header"
import { ApiService } from "../../Component/Elements/Services/apiservices"
import { useState, useEffect, useRef } from "react"
import Skeleton from "react-loading-skeleton"
import 'react-loading-skeleton/dist/skeleton.css'
import AboveFooter from "../Home/above_footer_sec"

const Team = () => {
    const [teamData, setTeamData] = useState([])
    const [loading, setloading] = useState(true)
    const [teamImgPath, setTeamImgPath] = useState([])
    const didMountRef = useRef(true);
    useEffect(() => {
        if (didMountRef.current) {
            getTeamData()
        }
        didMountRef.current = false;
    }, []);
    const getTeamData = () => {
        ApiService.fetchData("getTeamsData").then((res) => {
            if (res.status === "success") {
                setTeamData(res.resTeamData)
                setTeamImgPath(res.teamImagePath)
                setloading(false)
            }
            else {
                setloading(false)
            }
        }).catch((error) => {
            setloading(false)
        })
    }
    return (<>
        <Header></Header>
        <div class="edu-breadcrumb-area breadcrumb-style-2 bg-image bg-image--19">
            <div class="container">
                <div class="breadcrumb-inner">
                    <div class="page-title">
                        <h1 class="title">Our Team</h1>
                    </div>
                    <ul class="edu-breadcrumb">
                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                        <li class="separator"><i class="icon-angle-right"></i></li>
                        <li class="breadcrumb-item active" aria-current="page">Our Team</li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="edu-team-area team-area-1 gap-tb-text">
            <div class="container">
                <div class="section-title section-center" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                    <span class="pre-title">Our Team</span>
                    <h2 class="title">Meet Our Team</h2>
                    <span class="shape-line"><i class="icon-19"></i></span>
                </div>
                {loading ?
                <div class="row g-5">
                   { Array(6).fill().map((_, index) => (
                        <div className="col-lg-4 col-sm-6 col-12 mb--10" key={index}>
                            <div className="edu-team-grid team-style-1">
                                <div className="inner">
                                    <div className="thumbnail-wrap">
                                        <div className="thumbnail">
                                            <Skeleton height={'350px'} width={'350px'} />
                                        </div>
                                    </div>
                                    <div className="content">
                                        <Skeleton width={'150px'} height={'20px'} />
                                        <Skeleton width={'100px'} height={'15px'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    </div >
                    :
                    teamData && teamData.length > 0 ? <div class="row g-5">
                        {teamData.map((item, index) => (
                            <div class="col-lg-4 col-sm-6 col-12 mb--10" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800" key={index}>
                                <div class="edu-team-grid team-style-1">
                                    <div class="inner">
                                        <div class="thumbnail-wrap">
                                            <div class="thumbnail">
                                                <a href="team-details.html">
                                                    <img src="/images/team/team-01.webp" alt="team images" />
                                                </a>
                                            </div>

                                        </div>
                                        <div class="content">
                                            <h5 class="title"><a href="team-details.html">{item.team_name}</a></h5>
                                            <span class="designation">{item.team_designation}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div> : <div className="text-center">No Data Found</div>}


            </div>
        </div>
        <AboveFooter></AboveFooter>
        <Footer></Footer>

    </>)
}

export default Team