const OurClient = () => {
    return (<>

        <div class="edu-brand-area brand-area-1 section-gap-equal"  >
            <div class="container">
                <div class="row">
                    <div class="col-lg-5">
                        <div class="brand-section-heading">
                            <div class="section-title section-left" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                <span class="pre-title">Our Clients</span>
                                <h2 class="title">Trusted by Leading Brands</h2>
                                <span class="shape-line"><i class="icon-19"></i></span>
                                <p>We are proud to work with a diverse range of clients who trust us to deliver outstanding results and innovative solutions.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-7">
                        <div class="brand-grid-wrap">
                            <div class="brand-grid">
                                <img src="/images/brand/brand-01.png" alt="Brand Logo" />
                            </div>
                            <div class="brand-grid">
                                <img src="/images/brand/brand-02.png" alt="Brand Logo" />
                            </div>
                            <div class="brand-grid">
                                <img src="/images/brand/brand-03.png" alt="Brand Logo" />
                            </div>
                            <div class="brand-grid">
                                <img src="/images/brand/brand-04.png" alt="Brand Logo" />
                            </div>
                            <div class="brand-grid">
                                <img src="/images/brand/brand-05.png" alt="Brand Logo" />
                            </div>
                            <div class="brand-grid">
                                <img src="/images/brand/brand-06.png" alt="Brand Logo" />
                            </div>
                            <div class="brand-grid">
                                <img src="/images/brand/brand-07.png" alt="Brand Logo" />
                            </div>
                            <div class="brand-grid">
                                <img src="/images/brand/brand-08.png" alt="Brand Logo" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>




    </>)
}

export default OurClient