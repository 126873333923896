import React, { useState, useEffect, useRef } from "react";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { ApiService } from "../../Component/Elements/Services/apiservices";
import constant from "../../Component/Elements/Services/constant";
import moment from "moment";

const NewsBlogs = () => {
    const [blogData, setBlogData] = useState([])
    const [isLoading, setisLoading] = useState(false)
    const [blogImgUrl, setblogImgUrl] = useState('')
    const didMountRef = useRef(true);
    useEffect(() => {
        if (didMountRef.current) {
            getTestimonialData();
        }
        didMountRef.current = false
    }, []);
    const getTestimonialData = () => {
        setisLoading(true)
        ApiService.fetchData('featuredBlogsList').then((res) => {
            if (res.status === 'success') {
                setBlogData(res.blogsData)
                setblogImgUrl(res.blog_image_path)
                setisLoading(false)
            }
            else {
                setisLoading(false)
            }
        }).catch(() => {
            setisLoading(false)
        })
    }
    return (<>
        {isLoading ?
            <div className="edu-blog-area blog-area-6 bg-image section-gap-equal">
                <div className="container">
                    <div className="section-title section-center" data-sal-delay="100" data-sal="slide-up" data-sal-duration="800">
                        <span className="pre-title">
                            <Skeleton width={120} height={20} />
                        </span>
                        <h2 className="title">
                            <Skeleton width={`60%`} height={30} />
                        </h2>
                        <span className="shape-line">
                            <Skeleton width={50} height={10} />
                        </span>
                    </div>
                    <div className="row g-5">
                        {
                         Array.from({ length: 3 }).map((_, index) => (
                            <div
                              className="col-lg-4 col-md-6 col-12"
                              key={index}
                              data-sal-delay={`${100 * (index + 1)}`}
                              data-sal="slide-up"
                              data-sal-duration="800"
                            >
                              <div className="edu-blog blog-style-1">
                                <div className="inner">
                                  <div className="thumbnail">
                                    <Skeleton height={200} />
                                  </div>
                                  <div className="content position-top">
                                    <Skeleton width={100} />
                                    <Skeleton height={20} />
                                    <h5 className="title">
                                      <Skeleton width={"80%"} />
                                    </h5>
                                    <ul className="blog-meta">
                                      <li>
                                        <Skeleton width={50} />
                                      </li>
                                      <li>
                                        <Skeleton width={50} />
                                      </li>
                                    </ul>
                                    <p>
                                      <Skeleton count={2} />
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        
                        }
                    </div>
                </div>
            </div> :

            blogData && blogData.length > 0 && (
<div class="edu-blog-area blog-area-1 edu-section-gap">
    <div class="container">
        <div class="section-title section-center" data-sal-delay="100" data-sal="slide-up" data-sal-duration="800">
            <span class="pre-title">Latest Articles</span>
            <h2 class="title">Get News with EduBlink</h2>
            <span class="shape-line"><i class="icon-19"></i></span>
        </div>
        <div class="row g-5">
            {blogData.map((item, index)=>(
 <div class="col-lg-4 col-md-6 col-12" data-sal-delay="100" data-sal="slide-up" data-sal-duration="800" key={index}>
 <div class="edu-blog blog-style-1">
     <div class="inner">
         <div class="thumbnail">
             <a href="/blog-detail">
                 <img src="/images/blog/blog-01.jpg" alt="Blog Images" />
             </a>
         </div>
         <div class="content position-top">
             <div class="read-more-btn">
                 <a class="btn-icon-round" href="/blog-detail"><i class="icon-4"></i></a>
             </div>
             <h5 class="title"><a href="/blog-detail">{item.blog_name}</a></h5>
             <ul class="blog-meta">
                 <li><i class="icon-27"></i>{moment(item.updated_at).format("MMM DD, YYYY")}</li>
                 {/* <li><i class="icon-28"></i>Com 09</li> */}
             </ul>
             <p>{item.blog_short_description}</p>
         </div>
     </div>
 </div>
</div>
            ))}
           
   
           

        </div>
    </div>
    <ul class="shape-group">
        <li class="shape-1 scene">
            <img data-depth="-1.4" src="/images/about/shape-02.png" alt="Shape" />
        </li>
        <li class="shape-2 scene">
            <span data-depth="2.5"></span>
        </li>
        <li class="shape-3 scene">
            <img data-depth="-2.3" src="/images/counterup/shape-05.png" alt="Shape" />
        </li>
    </ul>
</div>

            )}




    </>)
}


export default NewsBlogs