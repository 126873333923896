import Footer from "../../Component/Footer"
import Header from "../../Component/Header"
import AboveFooter from "../Home/above_footer_sec"

const DirectorProfile = () => {
    return (<>
        <Header></Header>
        <div class="edu-breadcrumb-area">
            <div class="container">
                <div class="breadcrumb-inner">
                    <div class="page-title">
                        <h1 class="title">Director Profile</h1>
                    </div>
                    <ul class="edu-breadcrumb">
                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                        <li class="separator"><i class="icon-angle-right"></i></li>
                        <li class="breadcrumb-item active" aria-current="page">Director Profile</li>
                    </ul>
                </div>
            </div>
            <ul class="shape-group">
                <li class="shape-1">
                    <span></span>
                </li>
                <li class="shape-2 scene"><img data-depth="2" src="/images/about/shape-13.png" alt="shape" /></li>
                <li class="shape-3 scene"><img data-depth="-2" src="/images/about/shape-15.png" alt="shape" /></li>
                <li class="shape-4">
                    <span></span>
                </li>
                <li class="shape-5 scene"><img data-depth="2" src="/images/about/shape-07.png" alt="shape" /></li>
            </ul>
        </div>
        <div class="edu-team-details-area section-gap-equal">
            <div class="container">
                <div class="row row--40">
                    <div class="col-lg-4">
                        <div class="team-details-thumb">
                            <div class="thumbnail">
                                <img src="/images/team/team-18.webp" alt="team" />
                            </div>
                            <ul class="social-share">
                                <li>
                                    <a href="#"><i class="icon-share-alt"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i class="icon-facebook"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i class="icon-twitter"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i class="icon-linkedin2"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-8">
                        <div class="team-details-content">
                            <div class="main-info">
                                <span class="subtitle">Instructor</span>
                                <h3 class="title">Edward Narton</h3>
                                <span class="designation">Developer and Teacher</span>
                                <ul class="team-meta">
                                    <li><i class="icon-25"></i>20 Students</li>
                                    <li>
                                        <div class="rating">
                                            <i class="icon-23"></i>
                                            <i class="icon-23"></i>
                                            <i class="icon-23"></i>
                                            <i class="icon-23"></i>
                                            <i class="icon-23"></i>
                                        </div>
                                        <span class="rating-count">(720 Rating)</span>
                                    </li>
                                </ul>
                            </div>
                            <div class="bio-describe">
                                <h4 class="title">About Me</h4>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur elit sed do eius mod tempor incidid labore dolore magna aliqua. enim ad minim eniam quis nostrud exercitation ullamco laboris nisi aliquip ex commodo consequat. duis aute
                                    irure dolor in repreed ut perspiciatis unde omnis iste natus error sit voluptat em acus antium.
                                </p>
                                <p>doloremque laudantium totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi arch itecto beatae vitae dicta sunt explicabo.</p>
                            </div>
                            <div class="contact-info">
                                <h4 class="title">Contact Me</h4>
                                <ul>
                                    <li><span>Address:</span>North Helenavile, FV77 8WS</li>
                                    <li><span>Email:</span><a href="mailto:edublink@example.com" target="_blank">edublink@example.com</a></li>
                                    <li><span>Phone:</span><a href="tel:+37(417)683-4409">+37 (417) 683-4409</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <AboveFooter></AboveFooter>
        <Footer></Footer>

    </>)
}

export default DirectorProfile