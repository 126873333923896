import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Container/Home';
import ContactUs from './Container/ContactUs';
import AboutUs from './Container/Aboutus';
import BlogList from './Container/Blog';
import Gallery from './Container/Gallery';
import ProductDetail from './Container/ProductDetail';
import ProductList from './Container/product_list.js';
import BlogDetail from './Container/Blogdetail/index.js';
import GetaQuote from './Container/GetQuote/index.js';
import Team from './Container/Team/index.js';
import Certificates from './Container/Certificates/index.js';
import 'react-loading-skeleton/dist/skeleton.css'
import DirectorProfile from './Container/DirectorProfile/index.js';

function App() {
  return (
    <Router>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/certificates" element={<Certificates />} />
      <Route path="/our-team" element={<Team />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/blog" element={<BlogList />} />
      <Route path="/blog-detail" element={<BlogDetail />} />
      <Route path="/get-a-quote" element={<GetaQuote />} />
      <Route path="/gallery" element={<Gallery />} />
      <Route path="/product/:slug?" element={<ProductDetail />} />
      <Route path="/product-list/:type?" element={<ProductList />} />
      <Route path="/director-profile" element={<DirectorProfile />} />
    </Routes>
  </Router>
  );
}

export default App;
