import Footer from "../../Component/Footer"
import AboveFooter from "../Home/above_footer_sec"

const BlogDetail=()=>{
    return(<>
    <div id="main-wrapper" class="main-wrapper">
    <div class="edu-breadcrumb-area breadcrumb-style-2 bg-image bg-image--19">
        <div class="container">
            <div class="breadcrumb-inner">
                <div class="page-title">
                    <h1 class="title">Blog Details</h1>
                </div>
                <ul class="edu-breadcrumb">
                    <li class="breadcrumb-item"><a href="/">Home</a></li>
                    <li class="separator"><i class="icon-angle-right"></i></li>
                    <li class="breadcrumb-item active" aria-current="page">Blog Details</li>
                </ul>
            </div>
        </div>
    </div>

    <div class="blog-details-area section-gap-equal">
        <div class="container">
            <div class="row row--30">
                <div class="col-lg-8">
                    <div class="blog-details-content">
                        <div class="entry-content">
                            <span class="category">Developer</span>
                            <h3 class="title">How to Become Computer Working Days Software Engineer?</h3>
                            <ul class="blog-meta">
                                <li><i class="icon-27"></i>Oct 10, 2024</li>
                                <li><i class="icon-28"></i>Com 09</li>
                            </ul>
                            <div class="thumbnail">
                                <img src="/images/blog/blog-large-1.jpg" alt="Blog Image" />
                            </div>
                        </div>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo
                            consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit sed quia consequuntur magni
                            dolores.
                        </p>
                        <p>
                            Excepteur sint occaecat cupidatat non proident sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium totam
                            rem aperiam.
                        </p>
                        <ul>
                            <li>Aute irure dolor in reprehenderit</li>
                            <li>Occaecat cupidatat non proident sunt in culpa</li>
                            <li>Pariatur enim ipsam.</li>
                        </ul>
                        <blockquote>
                            <p>Lorem ipsum dolor amet con sectur elitadicing elit sed do usmod tempor uincididunt enim minim veniam nostrud.</p>
                            <h5 class="author">Simon Baker</h5>
                        </blockquote>
                    </div>

            
                 
                </div>
                <div class="col-lg-4">
                    <div class="edu-blog-sidebar">
                      
                        <div class="edu-blog-widget widget-search">
                            <div class="inner">
                                <h4 class="widget-title">Search</h4>
                                <div class="content">
                                    <form class="blog-search" action="#">
                                        <button class="search-button"><i class="icon-2"></i></button>
                                        <input type="text" placeholder="Search" />
                                    </form>
                                </div>
                            </div>
                        </div>
                   
                        <div class="edu-blog-widget widget-latest-post">
                            <div class="inner">
                                <h4 class="widget-title">Latest Post</h4>
                                <div class="content latest-post-list">
                                    <div class="latest-post">
                                        <div class="thumbnail">
                                            <a href="blog-details.html">
                                                <img src="/images/blog/small-post-01.jpg" alt="Blog Images" />
                                            </a>
                                        </div>
                                        <div class="post-content">
                                            <h6 class="title"><a href="blog-details.html">Instructional Design &amp; Adult Learners</a></h6>
                                            <ul class="blog-meta">
                                                <li><i class="icon-27"></i>22 Nov, 2024</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="latest-post">
                                        <div class="thumbnail">
                                            <a href="blog-details.html">
                                                <img src="/images/blog/small-post-02.jpg" alt="Blog Images" />
                                            </a>
                                        </div>
                                        <div class="post-content">
                                            <h6 class="title"><a href="blog-details.html">Believe in Yourself! Have Faith!</a></h6>
                                            <ul class="blog-meta">
                                                <li><i class="icon-27"></i>22 Nov, 2024</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="latest-post">
                                        <div class="thumbnail">
                                            <a href="blog-details.html">
                                                <img src="/images/blog/small-post-03.jpg" alt="Blog Images" />
                                            </a>
                                        </div>
                                        <div class="post-content">
                                            <h6 class="title"><a href="blog-details.html">Live Online Lessons & eLearning</a></h6>
                                            <ul class="blog-meta">
                                                <li><i class="icon-27"></i>22 Nov, 2024</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                  
                        <div class="edu-blog-widget widget-categories">
                            <div class="inner">
                                <h4 class="widget-title">Categories</h4>
                                <div class="content">
                                    <ul class="category-list">
                                        <li>
                                            <a href="#">Business Studies <span>(3)</span></a>
                                        </li>
                                        <li>
                                            <a href="#">Computer Engineering <span>(7)</span></a>
                                        </li>
                                        <li>
                                            <a href="#">Medical &amp; Health<span>(2)</span></a>
                                        </li>
                                        <li>
                                            <a href="#">Software <span>(1)</span></a>
                                        </li>
                                        <li>
                                            <a href="#">Web Development <span>(3)</span></a>
                                        </li>
                                        <li>
                                            <a href="#">Uncategorized <span>(9)</span></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
   
                       
                     
                    </div>
                </div>
            </div>
        </div>
    </div>

<AboveFooter></AboveFooter>
<Footer></Footer>
</div>

    
    </>)
}

export default BlogDetail