import Footer from "../../Component/Footer"
import Header from "../../Component/Header"

const ProductList = () => {
    return (<>
        <Header></Header>
        <div class="edu-course-area course-area-1 section-gap-equal">
            <div class="container">
                <div class="row g-5">
                    <div class="col-lg-3">
                        <div class="edu-course-sidebar">
                            <div class="edu-course-widget widget-category">
                                <div class="inner">
                                    <h5 class="widget-title">Filter by Categories</h5>
                                    <div class="content">
                                        <div class="edu-form-check">
                                            <input type="checkbox" id="cat-check1" checked />
                                            <label for="cat-check1">Art &amp; Design <span>(7)</span></label>
                                        </div>
                                        <div class="edu-form-check">
                                            <input type="checkbox" id="cat-check2" />
                                            <label for="cat-check2">Development <span>(2)</span></label>
                                        </div>
                                        <div class="edu-form-check">
                                            <input type="checkbox" id="cat-check3" />
                                            <label for="cat-check3">Business <span>(3)</span></label>
                                        </div>
                                        <div class="edu-form-check">
                                            <input type="checkbox" id="cat-check4" />
                                            <label for="cat-check4">Marketing <span>(6)</span></label>
                                        </div>
                                        <div class="edu-form-check">
                                            <input type="checkbox" id="cat-check5" />
                                            <label for="cat-check5">Academics <span>(2)</span></label>
                                        </div>
                                        <div class="edu-form-check">
                                            <input type="checkbox" id="cat-check6" />
                                            <label for="cat-check6">Data Science <span>(9)</span></label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="col-lg-9 col-pl--35">
                        <div class="edu-sorting-area">
                            <div class="sorting-left">
                                <h6 class="showing-text">We found <span>71</span> courses available for you</h6>
                            </div>
                            <div class="sorting-right">

                                <div class="edu-sorting">
                                    <div class="icon"><i class="icon-55"></i></div>
                                    <select class="edu-select">
                                        <option>Filters</option>
                                        <option>Low To High</option>
                                        <option>High Low To</option>
                                        <option>Last Viewed</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="row g-5">

                            <div class="col-md-6 col-lg-6" data-sal-delay="100" data-sal="slide-up" data-sal-duration="800">
                                <div class="edu-course course-style-3 course-box-shadow">
                                    <div class="inner">
                                        <div class="thumbnail">
                                            <a href="course-details.html">
                                                <img src="/images/course/course-08.jpg" alt="Course Meta" />
                                            </a>
                                            <div class="time-top">
                                                <span class="duration"><i class="icon-61"></i>Online + Onsite</span>
                                            </div>
                                        </div>
                                        <div class="content">
                                            <span class="course-level">Management</span>
                                            <h5 class="title">
                                                <a href="course-details.html">Public Administration</a>
                                            </h5>
                                            <p>Lorem ipsum dolor sit amet consectur elit sed eiusmod ex tempor incididunt labore dolore magna.</p>
                                            <div class="course-rating">
                                                <div class="rating">
                                                    <i class="icon-23"></i>
                                                    <i class="icon-23"></i>
                                                    <i class="icon-23"></i>
                                                    <i class="icon-23"></i>
                                                    <i class="icon-23"></i>
                                                </div>
                                                <span class="rating-count">(5.0 /7 Rating)</span>
                                            </div>
                                            <div class="read-more-btn">
                                                <a class="edu-btn btn-small btn-secondary" href="course-details.html">Learn More <i class="icon-4"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6 col-lg-6" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                <div class="edu-course course-style-3 course-box-shadow">
                                    <div class="inner">
                                        <div class="thumbnail">
                                            <a href="course-details.html">
                                                <img src="/images/course/course-09.jpg" alt="Course Meta" />
                                            </a>
                                            <div class="time-top">
                                                <span class="duration"><i class="icon-61"></i>Online + Onsite</span>
                                            </div>
                                        </div>
                                        <div class="content">
                                            <span class="course-level">Economics</span>
                                            <h5 class="title">
                                                <a href="course-details.html">Major in Economics</a>
                                            </h5>
                                            <p>Lorem ipsum dolor sit amet consectur elit sed eiusmod ex tempor incididunt labore dolore magna.</p>
                                            <div class="course-rating">
                                                <div class="rating">
                                                    <i class="icon-23"></i>
                                                    <i class="icon-23"></i>
                                                    <i class="icon-23"></i>
                                                    <i class="icon-23"></i>
                                                    <i class="icon-23"></i>
                                                </div>
                                                <span class="rating-count">(5.0 /7 Rating)</span>
                                            </div>
                                            <div class="read-more-btn">
                                                <a class="edu-btn btn-small btn-secondary" href="course-details.html">Learn More <i class="icon-4"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6 col-lg-6" data-sal-delay="200" data-sal="slide-up" data-sal-duration="800">
                                <div class="edu-course course-style-3 course-box-shadow">
                                    <div class="inner">
                                        <div class="thumbnail">
                                            <a href="course-details.html">
                                                <img src="/images/course/course-10.jpg" alt="Course Meta" />
                                            </a>
                                            <div class="time-top">
                                                <span class="duration"><i class="icon-61"></i>Online Only</span>
                                            </div>
                                        </div>
                                        <div class="content">
                                            <span class="course-level">Business</span>
                                            <h5 class="title">
                                                <a href="course-details.html">Business Studies</a>
                                            </h5>
                                            <p>Lorem ipsum dolor sit amet consectur elit sed eiusmod ex tempor incididunt labore dolore magna.</p>
                                            <div class="course-rating">
                                                <div class="rating">
                                                    <i class="icon-23"></i>
                                                    <i class="icon-23"></i>
                                                    <i class="icon-23"></i>
                                                    <i class="icon-23"></i>
                                                    <i class="icon-23"></i>
                                                </div>
                                                <span class="rating-count">(5.0 /7 Rating)</span>
                                            </div>
                                            <div class="read-more-btn">
                                                <a class="edu-btn btn-small btn-secondary" href="course-details.html">Learn More <i class="icon-4"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6 col-lg-6" data-sal-delay="100" data-sal="slide-up" data-sal-duration="800">
                                <div class="edu-course course-style-3 course-box-shadow">
                                    <div class="inner">
                                        <div class="thumbnail">
                                            <a href="course-details.html">
                                                <img src="/images/course/course-32.jpg" alt="Course Meta" />
                                            </a>
                                            <div class="time-top">
                                                <span class="duration"><i class="icon-61"></i>Online + Onsite</span>
                                            </div>
                                        </div>
                                        <div class="content">
                                            <span class="course-level">Management</span>
                                            <h5 class="title">
                                                <a href="course-details.html">Corporate Finance</a>
                                            </h5>
                                            <p>Lorem ipsum dolor sit amet consectur elit sed eiusmod ex tempor incididunt labore dolore magna.</p>
                                            <div class="course-rating">
                                                <div class="rating">
                                                    <i class="icon-23"></i>
                                                    <i class="icon-23"></i>
                                                    <i class="icon-23"></i>
                                                    <i class="icon-23"></i>
                                                    <i class="icon-23"></i>
                                                </div>
                                                <span class="rating-count">(5.0 /7 Rating)</span>
                                            </div>
                                            <div class="read-more-btn">
                                                <a class="edu-btn btn-small btn-secondary" href="course-details.html">Learn More <i class="icon-4"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6 col-lg-6" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                <div class="edu-course course-style-3 course-box-shadow">
                                    <div class="inner">
                                        <div class="thumbnail">
                                            <a href="course-details.html">
                                                <img src="/images/course/course-33.jpg" alt="Course Meta" />
                                            </a>
                                            <div class="time-top">
                                                <span class="duration"><i class="icon-61"></i>Online + Onsite</span>
                                            </div>
                                        </div>
                                        <div class="content">
                                            <span class="course-level">Economics</span>
                                            <h5 class="title">
                                                <a href="course-details.html">Business Administration</a>
                                            </h5>
                                            <p>Lorem ipsum dolor sit amet consectur elit sed eiusmod ex tempor incididunt labore dolore magna.</p>
                                            <div class="course-rating">
                                                <div class="rating">
                                                    <i class="icon-23"></i>
                                                    <i class="icon-23"></i>
                                                    <i class="icon-23"></i>
                                                    <i class="icon-23"></i>
                                                    <i class="icon-23"></i>
                                                </div>
                                                <span class="rating-count">(5.0 /7 Rating)</span>
                                            </div>
                                            <div class="read-more-btn">
                                                <a class="edu-btn btn-small btn-secondary" href="course-details.html">Learn More <i class="icon-4"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6 col-lg-6" data-sal-delay="200" data-sal="slide-up" data-sal-duration="800">
                                <div class="edu-course course-style-3 course-box-shadow">
                                    <div class="inner">
                                        <div class="thumbnail">
                                            <a href="course-details.html">
                                                <img src="/images/course/course-34.jpg" alt="Course Meta" />
                                            </a>
                                            <div class="time-top">
                                                <span class="duration"><i class="icon-61"></i>Online Only</span>
                                            </div>
                                        </div>
                                        <div class="content">
                                            <span class="course-level">Business</span>
                                            <h5 class="title">
                                                <a href="course-details.html">Financial Economics</a>
                                            </h5>
                                            <p>Lorem ipsum dolor sit amet consectur elit sed eiusmod ex tempor incididunt labore dolore magna.</p>
                                            <div class="course-rating">
                                                <div class="rating">
                                                    <i class="icon-23"></i>
                                                    <i class="icon-23"></i>
                                                    <i class="icon-23"></i>
                                                    <i class="icon-23"></i>
                                                    <i class="icon-23"></i>
                                                </div>
                                                <span class="rating-count">(5.0 /7 Rating)</span>
                                            </div>
                                            <div class="read-more-btn">
                                                <a class="edu-btn btn-small btn-secondary" href="course-details.html">Learn More <i class="icon-4"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6 col-lg-6" data-sal-delay="100" data-sal="slide-up" data-sal-duration="800">
                                <div class="edu-course course-style-3 course-box-shadow">
                                    <div class="inner">
                                        <div class="thumbnail">
                                            <a href="course-details.html">
                                                <img src="/images/course/course-35.jpg" alt="Course Meta" />
                                            </a>
                                            <div class="time-top">
                                                <span class="duration"><i class="icon-61"></i>Online + Onsite</span>
                                            </div>
                                        </div>
                                        <div class="content">
                                            <span class="course-level">Management</span>
                                            <h5 class="title">
                                                <a href="course-details.html">Data Science</a>
                                            </h5>
                                            <p>Lorem ipsum dolor sit amet consectur elit sed eiusmod ex tempor incididunt labore dolore magna.</p>
                                            <div class="course-rating">
                                                <div class="rating">
                                                    <i class="icon-23"></i>
                                                    <i class="icon-23"></i>
                                                    <i class="icon-23"></i>
                                                    <i class="icon-23"></i>
                                                    <i class="icon-23"></i>
                                                </div>
                                                <span class="rating-count">(5.0 /7 Rating)</span>
                                            </div>
                                            <div class="read-more-btn">
                                                <a class="edu-btn btn-small btn-secondary" href="course-details.html">Learn More <i class="icon-4"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6 col-lg-6" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                <div class="edu-course course-style-3 course-box-shadow">
                                    <div class="inner">
                                        <div class="thumbnail">
                                            <a href="course-details.html">
                                                <img src="/images/course/course-36.jpg" alt="Course Meta" />
                                            </a>
                                            <div class="time-top">
                                                <span class="duration"><i class="icon-61"></i>Online + Onsite</span>
                                            </div>
                                        </div>
                                        <div class="content">
                                            <span class="course-level">Economics</span>
                                            <h5 class="title">
                                                <a href="course-details.html">System Management</a>
                                            </h5>
                                            <p>Lorem ipsum dolor sit amet consectur elit sed eiusmod ex tempor incididunt labore dolore magna.</p>
                                            <div class="course-rating">
                                                <div class="rating">
                                                    <i class="icon-23"></i>
                                                    <i class="icon-23"></i>
                                                    <i class="icon-23"></i>
                                                    <i class="icon-23"></i>
                                                    <i class="icon-23"></i>
                                                </div>
                                                <span class="rating-count">(5.0 /7 Rating)</span>
                                            </div>
                                            <div class="read-more-btn">
                                                <a class="edu-btn btn-small btn-secondary" href="course-details.html">Learn More <i class="icon-4"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6 col-lg-6" data-sal-delay="200" data-sal="slide-up" data-sal-duration="800">
                                <div class="edu-course course-style-3 course-box-shadow">
                                    <div class="inner">
                                        <div class="thumbnail">
                                            <a href="course-details.html">
                                                <img src="/images/course/course-37.jpg" alt="Course Meta" />
                                            </a>
                                            <div class="time-top">
                                                <span class="duration"><i class="icon-61"></i>Online Only</span>
                                            </div>
                                        </div>
                                        <div class="content">
                                            <span class="course-level">Business</span>
                                            <h5 class="title">
                                                <a href="course-details.html">Computer Science</a>
                                            </h5>
                                            <p>Lorem ipsum dolor sit amet consectur elit sed eiusmod ex tempor incididunt labore dolore magna.</p>
                                            <div class="course-rating">
                                                <div class="rating">
                                                    <i class="icon-23"></i>
                                                    <i class="icon-23"></i>
                                                    <i class="icon-23"></i>
                                                    <i class="icon-23"></i>
                                                    <i class="icon-23"></i>
                                                </div>
                                                <span class="rating-count">(5.0 /7 Rating)</span>
                                            </div>
                                            <div class="read-more-btn">
                                                <a class="edu-btn btn-small btn-secondary" href="course-details.html">Learn More <i class="icon-4"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6 col-lg-6" data-sal-delay="100" data-sal="slide-up" data-sal-duration="800">
                                <div class="edu-course course-style-3 course-box-shadow">
                                    <div class="inner">
                                        <div class="thumbnail">
                                            <a href="course-details.html">
                                                <img src="/images/course/course-38.jpg" alt="Course Meta" />
                                            </a>
                                            <div class="time-top">
                                                <span class="duration"><i class="icon-61"></i>Online + Onsite</span>
                                            </div>
                                        </div>
                                        <div class="content">
                                            <span class="course-level">Management</span>
                                            <h5 class="title">
                                                <a href="course-details.html">Arts in Landscape</a>
                                            </h5>
                                            <p>Lorem ipsum dolor sit amet consectur elit sed eiusmod ex tempor incididunt labore dolore magna.</p>
                                            <div class="course-rating">
                                                <div class="rating">
                                                    <i class="icon-23"></i>
                                                    <i class="icon-23"></i>
                                                    <i class="icon-23"></i>
                                                    <i class="icon-23"></i>
                                                    <i class="icon-23"></i>
                                                </div>
                                                <span class="rating-count">(5.0 /7 Rating)</span>
                                            </div>
                                            <div class="read-more-btn">
                                                <a class="edu-btn btn-small btn-secondary" href="course-details.html">Learn More <i class="icon-4"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6 col-lg-6" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                <div class="edu-course course-style-3 course-box-shadow">
                                    <div class="inner">
                                        <div class="thumbnail">
                                            <a href="course-details.html">
                                                <img src="/images/course/course-39.jpg" alt="Course Meta" />
                                            </a>
                                            <div class="time-top">
                                                <span class="duration"><i class="icon-61"></i>Online + Onsite</span>
                                            </div>
                                        </div>
                                        <div class="content">
                                            <span class="course-level">Economics</span>
                                            <h5 class="title">
                                                <a href="course-details.html">Media and Communication</a>
                                            </h5>
                                            <p>Lorem ipsum dolor sit amet consectur elit sed eiusmod ex tempor incididunt labore dolore magna.</p>
                                            <div class="course-rating">
                                                <div class="rating">
                                                    <i class="icon-23"></i>
                                                    <i class="icon-23"></i>
                                                    <i class="icon-23"></i>
                                                    <i class="icon-23"></i>
                                                    <i class="icon-23"></i>
                                                </div>
                                                <span class="rating-count">(5.0 /7 Rating)</span>
                                            </div>
                                            <div class="read-more-btn">
                                                <a class="edu-btn btn-small btn-secondary" href="course-details.html">Learn More <i class="icon-4"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6 col-lg-6" data-sal-delay="200" data-sal="slide-up" data-sal-duration="800">
                                <div class="edu-course course-style-3 course-box-shadow">
                                    <div class="inner">
                                        <div class="thumbnail">
                                            <a href="course-details.html">
                                                <img src="/images/course/course-40.jpg" alt="Course Meta" />
                                            </a>
                                            <div class="time-top">
                                                <span class="duration"><i class="icon-61"></i>Online Only</span>
                                            </div>
                                        </div>
                                        <div class="content">
                                            <span class="course-level">Business</span>
                                            <h5 class="title">
                                                <a href="course-details.html">Design Management</a>
                                            </h5>
                                            <p>Lorem ipsum dolor sit amet consectur elit sed eiusmod ex tempor incididunt labore dolore magna.</p>
                                            <div class="course-rating">
                                                <div class="rating">
                                                    <i class="icon-23"></i>
                                                    <i class="icon-23"></i>
                                                    <i class="icon-23"></i>
                                                    <i class="icon-23"></i>
                                                    <i class="icon-23"></i>
                                                </div>
                                                <span class="rating-count">(5.0 /7 Rating)</span>
                                            </div>
                                            <div class="read-more-btn">
                                                <a class="edu-btn btn-small btn-secondary" href="course-details.html">Learn More <i class="icon-4"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Footer></Footer>

    </>)
}


export default ProductList