const AboutUs=()=>{
    return(<>
    
    <div class="edu-about-area about-style-3">
    <div class="container">
        <div class="row g-5 align-items-center">
            <div class="col-lg-6" data-sal-delay="50" data-sal="slide-up" data-sal-duration="800">
                <div class="about-content">
                    <div class="section-title section-left">
                        <span class="pre-title">About Us</span>
                        <h2 class="title">We Serve Innovative and  Quality <span class="color-primary">Products</span>  For You</h2>
                        <span class="shape-line"><i class="icon-19"></i></span>
                    </div>
                    <p><strong>GENOVIK BIOTECH PRIVATE LIMITED</strong> vision is to grow into a multi-product, organization addressing various public health challenges facing humanity. </p>
                    
                   <p> We focused on international collaboration and inlicensing to achieve its goal of becoming the leading provider of diagnostic kits/services in India with the most comprehensive tests covering disease predisposition, screening, diagnosis, classification, prognosis and therapeutic monitoring. It is the best partner to help innovative companies and leading research institutions to succeed in the Indian market. 
                    {/* It seeks opportunities to partner with diagnostic and pharmaceutical companies and universities on diagnostic technologies and products at all stages and in all disease areas. */}
                    </p>
                    <a href="/about-us" class="edu-btn btn-secondary">Know more us <i class="icon-4"></i></a>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="about-image-gallery">
                    <img class="main-img-1" data-sal-delay="100" data-sal="slide-up" data-sal-duration="800" src="/images/gnovik_img/about-04.jpg" alt="About Image" />
                    <img class="main-img-2" data-sal-delay="100" data-sal="slide-left" data-sal-duration="800" src="/images/gnovik_img/about-05.jpg" alt="About Image" />
                    <ul class="shape-group">
                        <li class="shape-1 scene" data-sal-delay="500" data-sal="fade" data-sal-duration="200">
                            <img data-depth="2" src="/images/about/shape-13.png" alt="Shape" />
                        </li>
                        <li class="shape-2 scene" data-sal-delay="500" data-sal="fade" data-sal-duration="200">
                            <img data-depth="-2" src="/images/about/shape-39.png" alt="Shape" />
                        </li>
                        <li class="shape-3 scene" data-sal-delay="500" data-sal="fade" data-sal-duration="200">
                            <img data-depth="2" src="/images/about/shape-07.png" alt="Shape" />
                        </li>
                        <li class="shape-4" data-sal-delay="500" data-sal="fade" data-sal-duration="200">
                            <span></span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <ul class="shape-group">
        <li class="shape-5">
            <img class="rotateit" src="/images/about/shape-13.png" alt="Shape" />
        </li>
        <li class="shape-6">
            <span></span>
        </li>
    </ul>
</div>

    </>)
}

export default AboutUs