import Header from '../../Component/Header/index'
import Footer from '../../Component/Footer/index'
import AboveFooter from '../Home/above_footer_sec'


const Gallery=()=>{
    return(<>
    <Header></Header>
    <div class="edu-breadcrumb-area">
    <div class="container">
        <div class="breadcrumb-inner">
            <div class="page-title">
                <h1 class="title">Gallery</h1>
            </div>
            <ul class="edu-breadcrumb">
                <li class="breadcrumb-item"><a href="/">Home</a></li>
                <li class="separator"><i class="icon-angle-right"></i></li>

                <li class="breadcrumb-item active" aria-current="page">Gallery</li>
            </ul>
        </div>
    </div>
    <ul class="shape-group">
        <li class="shape-1">
            <span></span>
        </li>
        <li class="shape-2 scene"><img data-depth="2" src="/images/about/shape-13.png" alt="shape" /></li>
        <li class="shape-3 scene"><img data-depth="-2" src="/images/about/shape-15.png" alt="shape" /></li>
        <li class="shape-4">
            <span></span>
        </li>
        <li class="shape-5 scene"><img data-depth="2" src="/images/about/shape-07.png" alt="shape" /></li>
    </ul>
</div>
<div class="edu-gallery-area edu-section-gap">
    <div class="container">
        <div id="masonry-gallery" class="gallery-grid-wrap">
            <div id="animated-thumbnials">
                <a href="/images/gallery/gallery-10.webp" class="edu-popup-image edu-gallery-grid p-gallery-grid-wrap masonry-item">
                    <div class="thumbnail">
                        <img src="/images/gallery/gallery-10.webp" alt="Gallery Image" />
                    </div>
                    <div class="zoom-icon">
                        <i class="icon-69"></i>
                    </div>
                </a>

                <a href="/images/gallery/gallery-01.webp" class="edu-popup-image edu-gallery-grid p-gallery-grid-wrap masonry-item">
                    <div class="thumbnail">
                        <img src="/images/gallery/gallery-01.webp" alt="Gallery Image" />
                    </div>
                    <div class="zoom-icon">
                        <i class="icon-69"></i>
                    </div>
                </a>

                <a href="/images/gallery/gallery-06.webp" class="edu-popup-image edu-gallery-grid p-gallery-grid-wrap masonry-item">
                    <div class="thumbnail">
                        <img src="/images/gallery/gallery-06.webp" alt="Gallery Image" />
                    </div>
                    <div class="zoom-icon">
                        <i class="icon-69"></i>
                    </div>
                </a>

                <a href="/images/gallery/gallery-05.webp" class="edu-popup-image edu-gallery-grid p-gallery-grid-wrap masonry-item">
                    <div class="thumbnail">
                        <img src="/images/gallery/gallery-05.webp" alt="Gallery Image" />
                    </div>
                    <div class="zoom-icon">
                        <i class="icon-69"></i>
                    </div>
                </a>

                <a href="/images/gallery/gallery-08.webp" class="edu-popup-image edu-gallery-grid p-gallery-grid-wrap masonry-item">
                    <div class="thumbnail">
                        <img src="/images/gallery/gallery-08.webp" alt="Gallery Image" />
                    </div>
                    <div class="zoom-icon">
                        <i class="icon-69"></i>
                    </div>
                </a>

                <a href="/images/gallery/gallery-04.webp" class="edu-popup-image edu-gallery-grid p-gallery-grid-wrap masonry-item">
                    <div class="thumbnail">
                        <img src="/images/gallery/gallery-04.webp" alt="Gallery Image" />
                    </div>
                    <div class="zoom-icon">
                        <i class="icon-69"></i>
                    </div>
                </a>

                <a href="/images/gallery/gallery-11.webp" class="edu-popup-image edu-gallery-grid p-gallery-grid-wrap masonry-item">
                    <div class="thumbnail">
                        <img src="/images/gallery/gallery-11.webp" alt="Gallery Image" />
                    </div>
                    <div class="zoom-icon">
                        <i class="icon-69"></i>
                    </div>
                </a>

                <a href="/images/gallery/gallery-09.webp" class="edu-popup-image edu-gallery-grid p-gallery-grid-wrap masonry-item">
                    <div class="thumbnail">
                        <img src="/images/gallery/gallery-09.webp" alt="Gallery Image" />
                    </div>
                    <div class="zoom-icon">
                        <i class="icon-69"></i>
                    </div>
                </a>

                <a href="/images/gallery/gallery-07.webp" class="edu-popup-image edu-gallery-grid p-gallery-grid-wrap masonry-item">
                    <div class="thumbnail">
                        <img src="/images/gallery/gallery-07.webp" alt="Gallery Image" />
                    </div>
                    <div class="zoom-icon">
                        <i class="icon-69"></i>
                    </div>
                </a>
            </div>
        </div>
    </div>
</div>

<AboveFooter></AboveFooter>
<Footer></Footer>
    </>)
}

export default Gallery