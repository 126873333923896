import Footer from "../../Component/Footer"
import Header from "../../Component/Header"
import AboveFooter from "../Home/above_footer_sec"


const BlogList = () => {

    return (<>

        <div id="main-wrapper" class="main-wrapper">
            <Header></Header>
            <div class="edu-breadcrumb-area">
                <div class="container">
                    <div class="breadcrumb-inner">
                        <div class="page-title">
                            <h1 class="title">Blogs</h1>
                        </div>
                        <ul class="edu-breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Home</a></li>
                            <li class="separator"><i class="icon-angle-right"></i></li>
                            <li class="breadcrumb-item active" aria-current="page">Blogs </li>
                        </ul>
                    </div>
                </div>
                <ul class="shape-group">
                    <li class="shape-1">
                        <span></span>
                    </li>
                    <li class="shape-2 scene"><img data-depth="2" src="/images/about/shape-13.png" alt="shape" /></li>
                    <li class="shape-3 scene"><img data-depth="-2" src="/images/about/shape-15.png" alt="shape" /></li>
                    <li class="shape-4">
                        <span></span>
                    </li>
                    <li class="shape-5 scene"><img data-depth="2" src="/images/about/shape-07.png" alt="shape" /></li>
                </ul>
            </div>


            <section class="section-gap-equal">
                <div class="container">
                    <div class="row g-5" id="masonry-gallery">

                        <div class="col-lg-4 col-md-6 col-12 masonry-item" data-sal-delay="100" data-sal="slide-up" data-sal-duration="800">
                            <div class="edu-blog blog-style-5">
                                <div class="inner">
                                    <div class="thumbnail">
                                        <a href="/blog-detail">
                                            <img src="/images/blog/blog-01.jpg" alt="Blog Images" />
                                        </a>
                                    </div>
                                    <div class="content position-top">
                                        <div class="read-more-btn">
                                            <a class="btn-icon-round" href="/blog-detail"><i class="icon-4"></i></a>
                                        </div>
                                        <div class="category-wrap">
                                            <a href="#" class="blog-category">ONLINE</a>
                                        </div>
                                        <h5 class="title"><a href="/blog-detail">Become a Better Blogger: Content Planning</a></h5>
                                        <ul class="blog-meta">
                                            <li><i class="icon-27"></i>Oct 10, 2024</li>
                                            <li><i class="icon-28"></i>Com 09</li>
                                        </ul>
                                        <p>Lorem ipsum dolor sit amet cons tetur adipisicing sed.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 masonry-item" data-sal-delay="100" data-sal="slide-up" data-sal-duration="800">
                            <div class="edu-blog blog-style-5">
                                <div class="inner">
                                    <div class="thumbnail">
                                        <a href="/blog-detail">
                                            <img src="/images/blog/blog-02.jpg" alt="Blog Images" />
                                        </a>
                                    </div>
                                    <div class="content position-top">
                                        <div class="read-more-btn">
                                            <a class="btn-icon-round" href="/blog-detail"><i class="icon-4"></i></a>
                                        </div>
                                        <div class="category-wrap">
                                            <a href="#" class="blog-category">Lecture</a>
                                        </div>
                                        <h5 class="title"><a href="/blog-detail">Fresh Inspiration For March And A...</a></h5>
                                        <ul class="blog-meta">
                                            <li><i class="icon-27"></i>Oct 10, 2024</li>
                                            <li><i class="icon-28"></i>Com 09</li>
                                        </ul>
                                        <p>Lorem ipsum dolor sit amet cons tetur adipisicing sed do eiusmod ux tempor incid idunt labore dol oremagna aliqua.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 masonry-item" data-sal-delay="100" data-sal="slide-up" data-sal-duration="800">
                            <div class="edu-blog blog-style-5">
                                <div class="inner">
                                    <div class="thumbnail">
                                        <a href="/blog-detail">
                                            <img src="/images/blog/blog-03.jpg" alt="Blog Images" />
                                        </a>
                                    </div>
                                    <div class="content position-top">
                                        <div class="read-more-btn">
                                            <a class="btn-icon-round" href="/blog-detail"><i class="icon-4"></i></a>
                                        </div>
                                        <div class="category-wrap">
                                            <a href="#" class="blog-category">Business</a>
                                        </div>
                                        <h5 class="title"><a href="/blog-detail">How to Developers Taking the Guess Work...</a></h5>
                                        <ul class="blog-meta">
                                            <li><i class="icon-27"></i>Oct 10, 2024</li>
                                            <li><i class="icon-28"></i>Com 09</li>
                                        </ul>
                                        <p>Lorem ipsum dolor sit amet cons tetur adipisicing sed do eiusmod ux tempor incid idunt.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 masonry-item" data-sal-delay="100" data-sal="slide-up" data-sal-duration="800">
                            <div class="edu-blog blog-style-5">
                                <div class="inner">
                                    <div class="thumbnail">
                                        <a href="/blog-detail">
                                            <img src="/images/blog/blog-19.jpg" alt="Blog Images" />
                                        </a>
                                    </div>
                                    <div class="content position-top">
                                        <div class="read-more-btn">
                                            <a class="btn-icon-round" href="/blog-detail"><i class="icon-4"></i></a>
                                        </div>
                                        <div class="category-wrap">
                                            <a href="#" class="blog-category">Business</a>
                                        </div>
                                        <h5 class="title"><a href="/blog-detail">How to Become Computer Working Days...</a></h5>
                                        <ul class="blog-meta">
                                            <li><i class="icon-27"></i>Oct 10, 2024</li>
                                            <li><i class="icon-28"></i>Com 09</li>
                                        </ul>
                                        <p>Lorem ipsum dolor sit amet cons tetur adipisicing sed do eiusmod ux tempor incid idunt labore ad dolore magna aliqua enim mini veniam quis nostrud exercitation.ullamco laboris.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 masonry-item" data-sal-delay="100" data-sal="slide-up" data-sal-duration="800">
                            <div class="edu-blog blog-style-5">
                                <div class="inner">
                                    <div class="thumbnail">
                                        <a href="/blog-detail">
                                            <img src="/images/blog/blog-21.jpg" alt="Blog Images" />
                                        </a>
                                    </div>
                                    <div class="content position-top">
                                        <div class="read-more-btn">
                                            <a class="btn-icon-round" href="/blog-detail"><i class="icon-4"></i></a>
                                        </div>
                                        <div class="category-wrap">
                                            <a href="#" class="blog-category">Lecture</a>
                                        </div>
                                        <h5 class="title"><a href="/blog-detail">Designing an Online Course from...</a></h5>
                                        <ul class="blog-meta">
                                            <li><i class="icon-27"></i>Oct 10, 2024</li>
                                            <li><i class="icon-28"></i>Com 09</li>
                                        </ul>
                                        <p>Lorem ipsum dolor sit amet cons tetur adipisicing sed do eiusmod ux tempor incid idunt labore dol oremagna aliqua.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 masonry-item" data-sal-delay="100" data-sal="slide-up" data-sal-duration="800">
                            <div class="edu-blog blog-style-5">
                                <div class="inner">
                                    <div class="thumbnail">
                                        <a href="/blog-detail">
                                            <img src="/images/blog/blog-20.jpg" alt="Blog Images" />
                                        </a>
                                    </div>
                                    <div class="content position-top">
                                        <div class="read-more-btn">
                                            <a class="btn-icon-round" href="/blog-detail"><i class="icon-4"></i></a>
                                        </div>
                                        <div class="category-wrap">
                                            <a href="#" class="blog-category">Online</a>
                                        </div>
                                        <h5 class="title"><a href="/blog-detail">Ten Benefits Of Rentals That May Change...</a></h5>
                                        <ul class="blog-meta">
                                            <li><i class="icon-27"></i>Oct 10, 2024</li>
                                            <li><i class="icon-28"></i>Com 09</li>
                                        </ul>
                                        <p>Lorem ipsum dolor sit amet cons tetur adipisicing sed.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 masonry-item" data-sal-delay="100" data-sal="slide-up" data-sal-duration="800">
                            <div class="edu-blog blog-style-5">
                                <div class="inner">
                                    <div class="thumbnail">
                                        <a href="/blog-detail">
                                            <img src="/images/blog/blog-23.jpg" alt="Blog Images" />
                                        </a>
                                    </div>
                                    <div class="content position-top">
                                        <div class="read-more-btn">
                                            <a class="btn-icon-round" href="/blog-detail"><i class="icon-4"></i></a>
                                        </div>
                                        <div class="category-wrap">
                                            <a href="#" class="blog-category">Bussiness</a>
                                        </div>
                                        <h5 class="title"><a href="/blog-detail">How to Keep Workouts Fresh in the...</a></h5>
                                        <ul class="blog-meta">
                                            <li><i class="icon-27"></i>Oct 10, 2024</li>
                                            <li><i class="icon-28"></i>Com 09</li>
                                        </ul>
                                        <p>Lorem ipsum dolor sit amet cons tetur adipisicing sed do eiusmod ux tempor incid idunt labore ad dolore magna aliqua enim mini veniam quis nostrud exercitation.ullamco laboris.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 masonry-item" data-sal-delay="100" data-sal="slide-up" data-sal-duration="800">
                            <div class="edu-blog blog-style-5">
                                <div class="inner">
                                    <div class="thumbnail">
                                        <a href="/blog-detail">
                                            <img src="/images/blog/blog-24.jpg" alt="Blog Images" />
                                        </a>
                                    </div>
                                    <div class="content position-top">
                                        <div class="read-more-btn">
                                            <a class="btn-icon-round" href="/blog-detail"><i class="icon-4"></i></a>
                                        </div>
                                        <div class="category-wrap">
                                            <a href="#" class="blog-category">Online</a>
                                        </div>
                                        <h5 class="title"><a href="/blog-detail">Become a Better Blogger: Content Planning</a></h5>
                                        <ul class="blog-meta">
                                            <li><i class="icon-27"></i>Oct 10, 2024</li>
                                            <li><i class="icon-28"></i>Com 09</li>
                                        </ul>
                                        <p>Lorem ipsum dolor sit amet cons tetur adipisicing sed.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 masonry-item" data-sal-delay="100" data-sal="slide-up" data-sal-duration="800">
                            <div class="edu-blog blog-style-5">
                                <div class="inner">
                                    <div class="thumbnail">
                                        <a href="/blog-detail">
                                            <img src="/images/blog/blog-22.jpg" alt="Blog Images" />
                                        </a>
                                    </div>
                                    <div class="content position-top">
                                        <div class="read-more-btn">
                                            <a class="btn-icon-round" href="/blog-detail"><i class="icon-4"></i></a>
                                        </div>
                                        <div class="category-wrap">
                                            <a href="#" class="blog-category">Lecture</a>
                                        </div>
                                        <h5 class="title"><a href="/blog-detail">Unveiling the Secrets of Online Teaching</a></h5>
                                        <ul class="blog-meta">
                                            <li><i class="icon-27"></i>Oct 10, 2024</li>
                                            <li><i class="icon-28"></i>Com 09</li>
                                        </ul>
                                        <p>Lorem ipsum dolor sit amet cons tetur adipisicing sed do eiusmod ux tempor incid idunt.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <ul class="edu-pagination top-space-30">
                        <li>
                            <a href="#" aria-label="Previous"><i class="icon-west"></i></a>
                        </li>
                        <li class="active"><a href="#">1</a></li>
                        <li><a href="#">2</a></li>
                        <li><a href="#">3</a></li>
                        <li class="more-next"><a href="#"></a></li>
                        <li><a href="#">8</a></li>
                        <li>
                            <a href="#" aria-label="Next"><i class="icon-east"></i></a>
                        </li>
                    </ul>
                </div>
            </section>

            <AboveFooter></AboveFooter>

            <Footer></Footer>
        </div>
    </>)

}


export default BlogList