import Footer from "../../Component/Footer"
import Header from "../../Component/Header"
import AboveFooter from "../Home/above_footer_sec"


const Certificates = () => {


 

    return (<>
        <Header></Header>
        <div class="edu-breadcrumb-area">
            <div class="container">
                <div class="breadcrumb-inner">
                    <div class="page-title">
                        <h1 class="title">Certificates</h1>
                    </div>
                    <ul class="edu-breadcrumb">
                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                        <li class="separator"><i class="icon-angle-right"></i></li>
                        <li class="breadcrumb-item active" aria-current="page">Certificates</li>
                    </ul>
                </div>
            </div>
            <ul class="shape-group">
                <li class="shape-1">
                    <span></span>
                </li>
                <li class="shape-2 scene"><img data-depth="2" src="/images/about/shape-13.png" alt="shape" /></li>
                <li class="shape-3 scene"><img data-depth="-2" src="/images/about/shape-15.png" alt="shape" /></li>
                <li class="shape-4">
                    <span></span>
                </li>
                <li class="shape-5 scene"><img data-depth="2" src="/images/about/shape-07.png" alt="shape" /></li>
            </ul>
        </div>


        <div class="edu-gallery-area edu-section-gap">
            <div class="container">
                <div class="isotope-wrapper">
                    <h5 className="text-center pb-5">GENOVIK BIOTECH PRIVATE LIMITED has prestigious certifications such as </h5>
                    <div class="isotope-list gallery-grid-wrap">
                        <div id="animated-thumbnials">
                            <a href="/images/gallery/gallery-01.webp" class="edu-popup-image edu-gallery-grid p-gallery-grid-wrap isotope-item education">
                                <div class="thumbnail">
                                    <img src="/images/gallery/gallery-01.webp" alt="Gallery Image" />
                                </div>
                                <h6 className="text-center pt-5">
                                    ISO 13485: 2016

                                </h6>
                            </a>

                            <a href="/images/gallery/gallery-02.webp" class="edu-popup-image edu-gallery-grid p-gallery-grid-wrap isotope-item marketing">
                                <div class="thumbnail">
                                    <img src="/images/gallery/gallery-02.webp" alt="Gallery Image" />
                                </div>
                                <h6 className="text-center pt-5">
                                    Certification of Incorporation from Government of India
                                </h6>
                            </a>

                            <a href="/images/gallery/gallery-03.webp" class="edu-popup-image edu-gallery-grid p-gallery-grid-wrap isotope-item education health">
                                <div class="thumbnail">
                                    <img src="/images/gallery/gallery-03.webp" alt="Gallery Image" />
                                </div>
                                <h6 className="text-center pt-5">
                                    Wholesale License for All Drugs except Schedule X
                                </h6>
                            </a>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <AboveFooter></AboveFooter>
        <Footer></Footer>
    </>)
}

export default Certificates